<template>
  <!-- Main content -->
  <section class="content">        
    <div class="btn-box"> 
        <el-button type="primary" @click="add">添加</el-button> 
      </div>
    <el-table
        v-loading="loading"
        :data="list"
        border>
      <el-table-column label="渠道名称" prop="channel_name"></el-table-column>
      <el-table-column label="渠道标识" prop="channel_key"></el-table-column>
      <el-table-column label="渠道链接">
          <template slot-scope="item">
              {{item.row.url}}
              <el-button type="text" size="small" v-clipboard:copy=item.row.url v-clipboard:success="copy" >复制</el-button>
          </template>
</el-table-column>
<el-table-column label="备注" prop="remark"></el-table-column>
<el-table-column label="图片地址">
    <template slot-scope="item">
        <!-- <img v-for="img in " :key="img" :src="img"> -->
        <img class="list" :src="item.row.image_url">
    </template>
</el-table-column>
<el-table-column label="开始时间">
    <template slot-scope="item">
        {{item.row.create_time | dateTime}}
    </template>
</el-table-column>
<el-table-column label="状态">
    <template slot-scope="item">
              {{item.row.status | statusType}}
          </template>
</el-table-column>

<el-table-column label="操作">
    <template slot-scope="scope">
      <el-button type="primary" size="mini" @click="alterDialg(scope.row.id)">修改</el-button></el-button>
      <el-button type="danger" size="mini" @click="del(scope.row.id,scope.row.channel_name)">删除</el-button></el-button>
    </template>
</el-table-column>
</el-table>
<el-pagination background layout="total, sizes,prev, pager, next,jumper" :total="total" @size-change="sizeChange" @current-change="currentChange">
</el-pagination>

<el-dialog title="添加/修改" :visible.sync="dialog" :close-on-click-modal=false>
    <el-form>
        <el-form-item label="渠道名称">
            <el-input v-model="channel_name" :disabled="id!=''"></el-input>
        </el-form-item>
        <el-form-item label="渠道标识">
            <el-input v-model="channel_key" :disabled="id!=''" maxlength="10"></el-input>
        </el-form-item>
        <el-form-item label="轮播图片">
            <div class="img-box">
                <div class="img" v-for="(item,index) in image_url" :key="index">
                    <img class="el-upload-list__item-thumbnail" :src="item" alt="">
                    <span class="el-upload-list__item-actions">
                        <span
                        class="el-upload-list__item-delete"
                        @click="remove(index)"
                        >
                        <i class="el-icon-delete"></i>
                        </span>
                    </span>
                </div>
                <el-upload action="" list-type="picture-card" :on-progress="uploadImg" :limit="5">
                    <i slot="default" class="el-icon-plus"></i>
                </el-upload>
            </div>
        </el-form-item>
        <el-form-item label="状态">
            <el-radio-group v-model="status">
                <el-radio :label="1">进行中</el-radio>
                <el-radio :label="2">已结束</el-radio>
            </el-radio-group>
        </el-form-item>
        <el-form-item label="备注">
            <el-input v-model="remark"></el-input>
        </el-form-item>
    </el-form>
    <div slot="footer" class="dialog-footer">
        <el-button @click="dialog = false">取 消</el-button>
        <el-button type="primary" @click="alter">确 定</el-button>
    </div>
</el-dialog>
</section>
</template>
<script>
    import * as api from '@/config/api'
    export default {
        name: 'promotionChannel',
        data() {
            return {
                loading: true,
                list: [],
                total: 0,
                page: 1,
                pagesize: 10,
                userPage: 1,
                userPagesize: 100,

                checked: false,

                dialog: false,
                fakerUser: false,
                isAlter: true,

                id: '',
                channel_name: '',
                channel_key: '',
                remark: '',
                image_url: [],
                status: 1,

                fakerUserList: [],
                checkFaker: [],
                fakerList: [],
                setList: [],

                status: '',
                locat: '/api/common.uploadImage',
                headers: {}
            }
        },
        watch: {},
        filters: {
            statusType(val) {
                let name
                switch (Number(val)) {
                    case 1:
                        name = '进行中'
                        break;
                    case 2:
                        name = '已结束'
                        break;
                    default:
                        name = ''
                        break;
                }
                return name
            },
            dateTime(val) {
                let day = new Date(Number(val) * 1000)
                if (Number(val) === 0) {
                    return ''
                }
                return day.getFullYear() + '-' + `${(day.getMonth() + 1) < 10 ? '0' + (day.getMonth() + 1) : (day.getMonth() + 1)}` + '-' + `${day.getDate()<10?'0'+day.getDate():day.getDate()}` + ' ' + `${day.getHours()<10?'0'+day.getHours():day.getHours()}` + ':' + `${day.getMinutes()<10?'0'+day.getMinutes():day.getMinutes()}` + ':' + `${day.getSeconds()<10?'0'+day.getSeconds():day.getSeconds()}`
            }
        },
        mounted() {
            this.getChannelList()
        },
        methods: {
            uploadImg(a, b, c) {
                let formData = new FormData();
                formData.append("file", b.raw);
                api.upLoadImg(
                    formData,
                    true,
                    res => {
                        if (this.image_url.length < 5) {
                            this.image_url.push(res.data.url)
                        } else {
                            this.$message({
                                message: '最多添加 5 张图片',
                                type: 'error'
                            })
                        }
                    })
            },
            // 复制链接
            copy() {
                this.$message({
                    message: '复制成功',
                    type: 'success'
                })
            },
            // 分页点击
            sizeChange(val) {
                this.pagesize = val
                this.getChannelList()
            },
            currentChange(val) {
                this.page = val
                this.getChannelList()
            },
            // 图片上传
            imgUrl(val) {
                this.image_url = val
            },
            // 修改弹窗
            alterDialg(id) {
                this.id = id
                api.getChannel({
                    id: id
                }, res => {
                    this.channel_name = res.data.channel_name
                    this.channel_key = res.data.channel_key
                    this.remark = res.data.remark
                    this.image_url = res.data.image_url
                    this.status = res.data.status
                    this.dialog = true
                })
            },
            // 添加弹窗
            add() {
                this.id = ""
                this.channel_name = ''
                this.channel_key = ''
                this.remark = ''
                this.image_url = []
                this.dialog = true
            },
            // 添加/修改
            alter() {
                if (this.id != '') {
                    api.updateChannel({
                        id: this.id,
                        channel_name: this.channel_name,
                        channel_key: this.channel_key,
                        remark: this.remark,
                        image_url: this.image_url,
                        status: this.status
                    }, res => {
                        this.$message({
                            message: '修改成功',
                            type: 'success'
                        })
                        this.getChannelList()
                        this.dialog = false
                    })
                    return
                }
                api.addChannel({
                    channel_name: this.channel_name,
                    channel_key: this.channel_key,
                    remark: this.remark,
                    image_url: this.image_url,
                    status: this.status
                }, res => {
                    this.$message({
                        message: '添加成功',
                        type: 'success'
                    })
                    this.getChannelList()
                    this.dialog = false
                })
            },
            // 删除
            del(id, name) {
                this.$confirm('是否确认删除 ' + name, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    api.delChannel({
                        id: id
                    }, res => {
                        this.$message({
                            type: 'success',
                            message: '删除成功'
                        });
                        this.getChannelList()
                    })
                }).catch(() => {})
            },
            getChannelList(page, size) {
                api.getChannelList({
                    page: page ? page : this.page,
                    pagesize: size ? size : this.pagesize,
                }, res => {
                    this.list = res.data.list
                    this.total = Number(res.data.count)
                    this.loading = false
                })
            },
            remove(index) {
                this.image_url.splice(index, 1)
            }
        }
    }
</script>
<style lang="scss">
    .btn-box {
        text-align: left;
        margin-bottom: 20px;
    }
    
    .none {
        color: transparent
    }
    
    .el-checkbox {
        margin-bottom: 10px;
        .el-checkbox__input {
            position: absolute;
            top: 0;
            right: 0;
        }
        .el-checkbox__label {
            text-align: center;
            img {
                width: 50px;
                display: block;
                margin: 0 auto;
            }
        }
    }
    
    .el-checkbox-button {
        float: left;
    }
    
    .faker-box {
        display: flex;
        flex-wrap: wrap;
        .faker-item {
            margin-right: 10px;
            text-align: center;
            img {
                display: block;
                margin: 0 auto;
                width: 50px;
            }
        }
    }
    
    .cursor {
        cursor: pointer;
    }
    
    .img-box {
        display: flex;
        flex-wrap: wrap;
    }
    
    .img {
        position: relative;
        margin-right: 5px;
        img {
            height: 148px;
        }
        .el-upload-list__item-actions {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            left: 0;
            color: #fff;
            opacity: 0;
            background-color: rgba(0, 0, 0, .5);
            transition: opacity .3s;
            text-align: center;
        }
        &:hover {
            .el-upload-list__item-actions {
                opacity: 1;
            }
            .el-upload-list__item-delete {
                display: block;
                color: #fff;
                cursor: pointer;
                font-size: 22px;
            }
        }
    }
    
    img.list {
        width: 100px;
    }
</style>